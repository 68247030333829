.contact {
  /* box-shadow: rgba(25,25,25,.04) 0 0 1px 0,rgba(0,0,0,.1) 0 3px 20px 0; */
  /* background-image: ; */
  background-image: url("https://i.ibb.co/GQfdQYC/BG-IMAGE.jpg");
  /* margin-top: 5%;
  margin-bottom: 5%; */
  /* height: 2000px; */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
  /* height: 100%; */
  
}

.contactform {
  /* box-shadow: rgba(25,25,25,.04) 0 0 1px 0,rgba(0,0,0,.1) 0 3px 20px 0; */
  background: white;
  width:35%;
  margin-top: 50px;
}

@media (max-width: 991px) {
     .contactform {
          /* box-shadow: rgba(25,25,25,.04) 0 0 1px 0,rgba(0,0,0,.1) 0 3px 20px 0; */
          background: white;
          width:100%;
        }

}

 